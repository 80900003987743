import React, { useState, useEffect } from 'react';
import Opponents from '../components/bunteliga/Opponents';
import Games from '../components/bunteliga/Games';
import axios from 'axios';
import Score from '../components/bunteliga/Score';
import Tournament from '../components/turniere/Tournament';
import Meetings from '../components/Meetings/Meetings';

function Events() {
    const [games, setGames] = useState([]);
    const [opponents, setOpponents] = useState([]);
    const [loading, setLoading] = useState([]);

    useEffect(() => {
        setLoading(true);
        axios
            .get('api/Games')
            .then((response) => {
                setGames(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        setLoading(true);
        axios
            .get('api/Opponents')
            .then((response) => {
                setOpponents(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    return (
        <>
            <div className='container-fluid'>
                <p className="rakete-font rakete-rot fs-4 my-2 mb-2 fw-semibold ps-3 text-uppercase">Bunte Liga</p>
                <div className="row mt-3">
                    <div className='col-md-6'>
                        <Score games={games} />
                    </div>
                    <div className='col-md-6'>
                        <Games games={games} opponents={opponents} />
                        <Opponents opponents={opponents} />
                    </div>
                </div>
                <Tournament />
                <Meetings />
            </div>
        </>
    )
}

export default Events;
