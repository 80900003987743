import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { RiDeleteBinLine } from "react-icons/ri";
import { IoMdArrowRoundBack } from "react-icons/io";
import { MdOutlinePaid } from "react-icons/md";
import { HiOutlinePlus } from "react-icons/hi";
import { LuLogOut } from "react-icons/lu";
import { TbEditCircle } from "react-icons/tb";
import TournamentEdit from '../components/turniere/TournamentEdit';
import PartificantAdd from '../components/turniere/partificantAdd';


function Tournaments() {
    const [tournament, setTournament] = useState({});
    const [loading, setLoading] = useState(true);
    const [participants, setParticipants] = useState([]);
    const [participantsConfirmed, setParticipantsConfirmed] = useState([]);
    const [participantsWait, setParticipantsWait] = useState([]);
    const [participantsCanceled, setParticipantsCanceled] = useState([]);
    const [inquiry, setInquiry] = useState([]);
    const [editTournament, setEditTournament] = useState(false);
    const [addPartificant, setAddPartificant] = useState(false);

    const { id } = useParams();

    const formatGermanDateAndTime = (date) => {
        return dayjs(date).locale('de').format('DD.MM.YYYY HH:mm');
    };

    const formatGermanDate = (date) => {
        return dayjs(date).locale('de').format('DD.MM.YYYY');
    };

    useEffect(() => {
        axios
            .get(`/tournament/${id}`)
            .then((response) => {
                setTournament(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, [id]);

    useEffect(() => {
        axios
            .get(`/tournament/${id}/participants`)
            .then((response) => {
                const data = response.data.data;
                setParticipants(data);
                const paidTrue = data.filter(participant => participant.paid === true && participant.canceled !== true);
                const Canceled = data.filter(participant => participant.canceled === true);
                setInquiry(data.filter(participant => participant.paid !== true && participant.canceled !== true));         
                setParticipantsCanceled(Canceled)
                if (paidTrue.length > tournament.teams) {
                    setParticipantsConfirmed(paidTrue.slice(0, tournament.teams));
                    setParticipantsWait(paidTrue.slice(tournament.teams));
                } else {
                    setParticipantsConfirmed(paidTrue);

                }
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, [id, tournament.teams]);

    function handleParticipantPaid(id, participantId, x) {
        setLoading(true);
        const formData = { paid: x }

        axios
            .put(`/tournament/${id}/participants/${participantId}`, formData)
            .then(() => {
                window.location.reload()
                setLoading(false);
                

            })
            .catch((error) => {
                setLoading(false);
                alert("An error occured. Please check Console");
                console.log(error);
            });

    };

    function handleParticipantCanceled(id, participantId) {
        setLoading(true);

        const formData = { canceled: true }

        axios
            .put(`/tournament/${id}/participants/${participantId}`, formData)
            .then(() => {
                setLoading(false);
                window.location.reload()
            })
            .catch((error) => {
                setLoading(false);
                alert("An error occured. Please check Console");
                console.log(error);
            });

    };

    function handleParticipantDelete(id, participantId) {
        setLoading(true);

        axios
            .delete(`/tournament/${id}/participants/${participantId}`)
            .then(() => {
                setLoading(false);
                window.location.reload()
            })
            .catch((error) => {
                setLoading(false);
                alert("An error occured. Please check Console");
                console.log(error);
            });

    };

    return (
        <>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div className='row container-fluid justify-content-center'>
                    <div className='row mx-3'>
                        <div className="col-1 mt-4"><Link to={"/events"}><IoMdArrowRoundBack size={35} className="back-button" /></Link></div>
                        <div className='col-9'>
                            <p className='rakete-font fw-bold mt-4 fs-3 text-center text-uppercase rakete-rot'>{tournament.name}</p>
                        </div>
                    </div>
                    <div className='col-lg-4 rounded shadow-sm mt-2 mx-2'>
                        <div className='row justify-content-center align-items-center my-1 mx-2'>
                            <div className='col-9 text-center rakete-font fs-5 my-3 text-uppercase fw-semibold ms-5'>Infos</div>
                            <div className='col-1'><TbEditCircle size={22} className='icon-edit' onClick={() => setEditTournament(true)} title="Informationen bearbeiten"/></div>
                        </div>
                        
                        <div className='my-2 text-center'><img className="tournament-thumb rounded shadow-sm" src={`https://server.raketefreiburg.de/uploads/${tournament.picture}`} alt={`${tournament.name}`} /></div>
                        <div className='row align-items-center my-1'>
                            <div className="text-center rakete-font">{tournament.location}</div>
                        </div>
                        <div className="text-center rakete-font">{formatGermanDateAndTime(tournament.date)} Uhr</div>


                        <div className='row mt-2 shadow-sm rounded mx-2 my-3'>
                            <div className='col-md'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" colSpan="2" className='text-center rakete-font text-uppercase fs-7 fw-semibold'>Regeln</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='rakete-font fs-9'>Turniergröße</td>
                                            <td className="fs-6 f">{tournament.teams} Teams</td>
                                        </tr>
                                        <tr>
                                            <td className='rakete-font fs-9'>Spieleranzahl</td>
                                            <td className='fs-6'>{tournament.modus} Spieler</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='col'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" colSpan="2" className='text-center rakete-font text-uppercase fs-7 fw-semibold'>Teams</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='rakete-font fs-9'>Anmeldungen</td>
                                            <td className="fs-6">{participants.length}</td>
                                        </tr>
                                        <tr>
                                            <td className='rakete-font fs-9'>Zugelassen</td>
                                            <td className='fs-6'>{participantsConfirmed.length} / {tournament.teams}</td>
                                        </tr>
                                        <tr>
                                            <td className='rakete-font fs-9'>Warteliste</td>
                                            <td className='fs-6'>{participantsWait.length}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='text-uppercase fw-semibold rakete-font text-center'>Infotext</div>
                        <div className='mx-3 my-3'>{tournament.info}</div>
                    </div>
                    <div className='col-lg-6 rounded shadow-sm mt-2 mx-2 py-3'>
                        <div>
                            <div className='row mb-1'>
                                <div className='col text-end rakete-font fs-5 text-uppercase fw-semibold'>Anfragen</div>
                                <div className='col-5 fw-semibold'>( {inquiry.length} )</div>
                            </div>
                            <div className="text-center">
                                {inquiry && inquiry.map((participant, index) => (
                                    <div key={participant._id} className=" newsline rounded-5 row py-2 mx-2 d-flex align-items-center justify-content-center">
                                        <div className="col-5 rakete-font fs-7 text-start fw-semibold mx-2">{participant.teamname}</div>
                                        <div className="col-4 ms-1 text-end text-wrap fs-9">{formatGermanDate(participant.createdAt)}</div>

                                        <div className="col text-end">
                                            <MdOutlinePaid size={21} className='hover icon-edit mx-1 my-1' onClick={() => handleParticipantPaid(id, participant._id, true)} title="Teilnehmer hat bezahlt" />
                                            <RiDeleteBinLine size={22} className='hover icons mx-1 my-1' onClick={() => handleParticipantDelete(id, participant._id)} title="Anfrage löschen"/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <div className='row mt-3 mb-1'>
                                <div className='col text-end rakete-font fs-5 text-uppercase fw-semibold '>Teilnehmer</div>
                                <div className='col-3 fw-semibold'>( {participantsConfirmed.length} / {tournament.teams} )</div>
                                <div className='col-2 fw-semibold'><HiOutlinePlus size={20} className='mx-4 hover icon-edit' onClick={() => setAddPartificant(true)} title="Teilnehmer hinzufügen"/></div>
                            </div>
                            <div className="text-center">
                                {participantsConfirmed && participantsConfirmed.map((team, index) => (
                                    <div key={team._id} className=" newsline rounded-5 row mx-2 py-2 d-flex align-items-center justify-content-center">
                                        <div className="col-5 rakete-font fs-7 fw-semibold mx-2 text-start text-success">{team.teamname}</div>
                                        <div className="col-4 ms-1 text-end text-wrap fs-9">{formatGermanDate(team.createdAt)}</div>
                                        <div className="col-2 text-end">
                                            <LuLogOut size={20} className='hover icon-edit mx-1 my-1' onClick={() => handleParticipantCanceled(id, team._id)} title="Teilnehmer vom Turnier abmelden" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <div className='row mt-3'>
                                <div className='col'><p className='text-end rakete-font fs-5 text-uppercase fw-semibold '>Warteliste</p></div>
                                <div className='col-5'><p className='fw-semibold'>( {participantsWait.length} / 3 )</p></div>
                            </div>
                            <div className="text-center">
                                {participantsWait && participantsWait.map((participant, index) => (
                                    <div key={participant._id} className=" newsline rounded-5 mx-2 row py-2 d-flex align-items-center justify-content-center">
                                        <div className="col-5 rakete-font fs-7 fw-semibold mx-2 text-start text-warning">{participant.teamname}</div>
                                        <div className="col-4 ms-1 text-end text-wrap fs-9">{formatGermanDate(participant.createdAt)}</div>
                                        <div className="col-2 text-end">
                                            <LuLogOut size={20} className='hover icon-edit mx-1 my-1' onClick={() => handleParticipantCanceled(id, participant._id)} title="Teilnehmer vom Turnier abmelden" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <div className='row mt-1'>
                                <div className='col'><p className='text-end rakete-font fs-5 text-uppercase fw-semibold '>Abmeldungen</p></div>
                                <div className='col-5'><p className='fw-semibold'>( {participantsCanceled.length} )</p></div>
                            </div>
                            <div className="text-center">
                                {participantsCanceled && participantsCanceled.map((participant, index) => (
                                    <div key={participant._id} className=" newsline rounded-5 mx-2 py-2 row justify-content-center align-items-center">
                                        <div className="col-5 rakete-font fs-7 fw-semibold text-start mx-2 text-danger">{participant.teamname}</div>
                                        <div className="col-4 ms-1 text-end text-wrap fs-9">{formatGermanDate(participant.createdAt)}</div>
                                        <div className="col-2 text-end">
                                            {participant.paid ? (
                                                <MdOutlinePaid size={22} className='hover icon-edit mx-1 my-1' onClick={() => handleParticipantPaid(id, participant._id, false)} title="Teilnehmer wurde die Gebühr zurückerstattet"/>
                                            ) : (
                                                <RiDeleteBinLine size={22} className='hover icons mx-1 my-1' onClick={() => handleParticipantDelete(id, participant._id)} title="Teilnehmer löschen"/>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <TournamentEdit show={editTournament} onHide={() => setEditTournament(false)} tournament={tournament} />
            <PartificantAdd show={addPartificant} onHide={() => setAddPartificant(false)} tournamentid={tournament._id} />
        </>
    );
}

export default Tournaments;
