import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';

function NewsDelete({ show, onHide, id }) {
    const [loading, setLoading] = useState(false);
  
    const handleNewsDelete = () => {
      setLoading(true);
      axios
        .delete(`api/News/${id}`)
        .then(() => {
          setLoading(false);
          onHide();
          window.location.reload();
        })
        .catch(error => {
          setLoading(false);
          console.error('Error deleting news:', error);
        });
    };
  
    return (
      <Modal show={show} onClose={onHide} onHide={onHide} size="md">
        <Modal.Header className='rakete-font text-uppercase fw-semibold rakete-rot' closeButton>News löschen</Modal.Header>
        <Modal.Body className='mt-2'>
              Bist du dir sicher, dass du diese News löschen möchtest?
        </Modal.Body>
        <Modal.Footer className='border border-0'>
          <button onClick={handleNewsDelete} className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7" disabled={loading}>Löschen</button>
          <button className="text-center decline-button text-uppercase rounded fw-semibold fs-7" onClick={onHide} disabled={loading}>
            Verwerfen
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
export default NewsDelete;