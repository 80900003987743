import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import Form from 'react-bootstrap/Form';


function NewsEdit({ show, onHide, news, id }) {
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        if (news) {
            setTitle(news.title);
            setContent(news.content);
        }
    }, [news]);
    const closeNewsEdit = () => {
        onHide();
    }

    if(!news) {
        return null
    }

    const handleEditNews = () => {
        const formData = {
            title, content}

        setLoading(true);
    
        axios
          .put(`/api/News/${id}`, formData)
          .then(() => {
            setLoading(false);
            window.location.reload();
          })
          .catch((error) => {
            setLoading(false);
            alert("An error occured. Please check Console");
            console.log(error);
          });
    
      };

    return (
        <>
            <Modal show={show} onHide={closeNewsEdit} size="md">
                <Modal.Header className='rakete-font rakete-rot text-uppercase fw-semibold fs-5' closeButton onClick={closeNewsEdit}>Neue Newsline</Modal.Header>
                <Modal.Body>
                {news.picture && (
                    <img src={`https://server.raketefreiburg.de/uploads/${news.picture}`} className="img-fluid rounded shadow-sm border" alt={`News: ${news.title}`} />
                )}                    <div>
                        <div className="mb-2 block">
                            <Form.Label value="Titel" />
                        </div>
                        <Form.Control
                            id="title"
                            placeholder="Hier könnte ein geiler Titel stehen..."
                            type="text"
                            className='form-control-sm'
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <div className="">
                        <div className="mb-2 block">
                            <Form.Label value="Content" />
                        </div>
                        <Form.Control
                            as="textarea"
                            id="content"
                            className='form-control-sm'
                            placeholder="Rakete ist geil..."
                            rows={4}
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7" onClick={handleEditNews} type="submit">Speichern</button>
                    <button className="text-center decline-button text-uppercase rounded fw-semibold fs-7" onClick={closeNewsEdit}>
                        Verwerfen
                    </button>
                </Modal.Footer>
            </Modal>
        </>

    );
}

export default NewsEdit;