import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { HiOutlinePlus } from "react-icons/hi";
import TournamentAdd from './TournamentAdd';
import { useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/de';

function Tournament() {
  const [addTournament, setAddTournament] = useState(false);
  const [tournaments, setTournaments] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    axios
      .get('/tournament')
      .then((response) => {
        const reversedTournaments = response.data.data.reverse();
        setTournaments(reversedTournaments);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const formatGermanDate = (date) => {
    return dayjs(date).locale('de').format('DD.MM.YYYY HH:mm');
  };

  
  const linkStyle = {
    textDecoration: "none",
  };

  return (
    <>
      <div className='row d-flex align-items-center justify-content-center'>
        <div className='col-9'><p className='fw-semibold rakete-font rakete-rot fs-4 mt-3 ms-3 text-start text-uppercase'>TURNIERE</p></div>
        <div className='col-3 text-end'><HiOutlinePlus size={28} className='mx-4 hover icon-edit' onClick={() => setAddTournament(true)} /></div>
      </div>
      <div className="row justify-content-center d-flex align-items-center">
      {tournaments.map((tournament, index) => (     
          <div className="col-md-3 border border-0 rounded mt-4 shadow-sm card mx-2 py-2 news-card" key={tournament._id}>
            <Link key={tournament._id} to={`/tournament/${tournament._id}`} style={linkStyle}>
            <div className=' my-2'><img className="news-img rounded-top" src={`https://server.raketefreiburg.de/uploads/${tournament.picture}`} alt={`${tournament.name}`} /></div>
            <p className="rakete-font rakete-rot text-center fs-5 my-2 mb-2 fw-semibold underline0">{tournament.name}</p> 
              <p className="rakete-font fs-7 text-dark fw-semibold text-uppercase underline0 pb-3 text-center">{formatGermanDate(tournament.date)}</p>
              </Link>
          </div>
      ))}
      </div>
      <TournamentAdd show={addTournament} onHide={() => setAddTournament(false)} />
    </>
  )
}

export default Tournament;