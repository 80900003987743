import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useAuth } from '../../provider/auth.js';
import { toast } from 'react-toastify';

function NewsCreate({ show, onHide }) {
    const { user } = useAuth()
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [picture, setPicture] = useState('');
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState();
    const [author, setAuthor] = useState("");
  
    useEffect(() => {
      setLoading(true);
      axios.get(`/members/${user}`)
        .then((response) => {
          setAuthor(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }, [user]);
  
    function handleChange(event) {
      setPicture(event.target.files[0]);
      setPreview(URL.createObjectURL(event.target.files[0]));
    }
  
    const closeNewsCreate = () => {
      setPreview();
      onHide();
  
    }
  
    const handleSaveNews = () => {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('authorId', user);
      formData.append("author", author.forename + " " + author.lastname)
      formData.append('content', content);
      formData.append('picture', picture);
  
      setLoading(true);
  
      axios
        .post("/api/News", formData)
        .then(() => {
          setLoading(false);
          setPreview();
          toast.done("News created")
          window.location.reload();
        })
        .catch((error) => {
          setLoading(false);
          alert("An error occured. Please check Console");
          console.log(error);
        });
  
    };
  
    return (
      <>
        <Modal show={show} onHide={closeNewsCreate} size="md">
          <Modal.Header className='rakete-font rakete-rot text-uppercase fw-semibold fs-5' closeButton onClick={closeNewsCreate}>Neue Newsline</Modal.Header>
          <Modal.Body>
            <img src={preview} className="img-fluid img-preview"/>
            <div id="fileUpload" className="">
              <div className="mb-2 block">
                <Form.Label value="Bild" />
              </div>
              <Form.Control className="form-control-sm" id="file" type="file" onChange={handleChange} accept="image/*" />
            </div>
            <div>
              <div className="mb-2 block">
                <Form.Label value="Titel" />
              </div>
              <Form.Control
                id="title"
                placeholder="Hier könnte ein geiler Titel stehen..."
                type="text"
                className='form-control-sm'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="">
              <div className="mb-2 block">
                <Form.Label value="Content" />
              </div>
              <Form.Control
                as="textarea"
                id="content"
                className='form-control-sm'
                placeholder="Rakete ist geil..."
                rows={4}
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
          <button className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7" onClick={handleSaveNews} type="button" disabled={!picture || !title || !content}>Speichern</button>
            <button className="text-center decline-button text-uppercase rounded fw-semibold fs-7" onClick={closeNewsCreate} disabled={loading} >
              Verwerfen
            </button>
          </Modal.Footer>
        </Modal>
      </>
  
    );
  };

export default NewsCreate;