import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import axios from "axios";
import { Modal } from 'react-bootstrap';
import { getRoleLabel } from '../../helpers/role';

function VorstandAdd({ show, onHide, members, role, index }) {
    const [forename, setForename] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [vorstand, setVorstand] = useState("");

    const handleAddVorstand = (id) => {
        const formData = { role }
        setLoading(true);

        axios
            .put(`/members/${id}`, formData)
            .then(() => {
                setLoading(false);
                window.location.reload();
            })
            .catch((error) => {
                setLoading(false);
                alert("An error occurred. Please check Console");
                console.log(error);
            });
    };

    const isVorstandSelected = vorstand !== "";
    const filteredMembers = members.filter((member) => !member.role);

    return (
        <Modal show={show} onHide={onHide} centered size="sm">
            <Modal.Header className='bg-light justify-content-center border border-0 rakete-rot' closeButton>
                <Modal.Title className='rakete-font fw-semibold text-uppercase fs-6 text-center'>{index}. {getRoleLabel(role)} </Modal.Title>
            </Modal.Header>
            <Form.Group className="px-3 mt-3">
                <Form.Label value="Rolle" className='fs-8 text-uppercase rakete-rot rakete-font'>Wähle ein Mitglied aus</Form.Label>
                <Form.Select
                    size="sm"
                    type="text"
                    value={vorstand}
                    onChange={(e) => setVorstand(e.target.value)}
                >
                    <option value="">Mitglied...</option>
                    {filteredMembers.map((member, index) => (
                        <option value={member._id} key={index + 1}>{member.forename} {member.lastname}</option>
                    ))}
                </Form.Select>
            </Form.Group>

            <Modal.Footer className='border border-0 fs-8'>
                <div className='pt-2 div-justify-middle'>
                    <button
                        className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7 mx-1"
                        type="button"
                        onClick={() => handleAddVorstand(vorstand)}
                        disabled={!isVorstandSelected}
                    >
                        Speichern
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default VorstandAdd;
