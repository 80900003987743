import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { HiOutlinePlus } from "react-icons/hi";
import { TbEditCircle } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import NewsPreview from '../components/news/newsPreview';
import NewsDelete from '../components/news/newsDelete';
import NewsCreate from '../components/news/newsCreate';
import NewsDetail from '../components/news/newsDetail';
import NewsEdit from '../components/news/newsEdit';
import { RiLockPasswordLine } from "react-icons/ri";

function News() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedNewsId, setSelectedNewsId] = useState(null);
  const [showNews, setShowNews] = useState(false);
  const [createNews, setCreateNews] = useState(false);
  const [deleteNews, setdeleteNews] = useState(false);
  const [selectedNews, setSelectedNews] = useState(0);
  const [editNews, setEditNews] = useState(false);

  const handleNewsCreate = () => {
    setCreateNews(true);
  };

  const handleNewsEdit = (id) => {
    setEditNews(true);
    setSelectedNewsId(id);
  }

  const handleNewsDetail = (id) => {
    setShowNews(true);
    setSelectedNewsId(id);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get('api/News')
      .then((response) => {
        const reversedNews = response.data.data.reverse();
        setNews(reversedNews);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  const handleNewsPreviewClick = (index) => {
    setSelectedNews(index);
  };


  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-lg'>
          <NewsPreview newsData={news} newsIndex={selectedNews} onNewsClick={handleNewsDetail} />
        </div>
        <div className='col-lg-6'>
          <div className="px-3">
            <div className='row d-flex align-items-center'>
              <div className='col-9'><p className='fw-semibold rakete-font rakete-rot fs-3 mt-3 ms-3 text-start'>NEWSLINES</p></div>
              <div className='col-3 text-end'><HiOutlinePlus size={28} className='mx-4 hover icon-edit' onClick={() => handleNewsCreate()} /></div>
            </div>
            <div className=' fs-7'>
              <div className="text-center">
                {news.map((newsItem, index) => (
                  <div key={newsItem._id} className=" newsline rounded-5 row py-2 d-flex align-items-center justify-content-center" onClick={() => handleNewsPreviewClick(index)}>
                    <div className='col-2 rakete-font'>{index + 1}.</div>
                    <div className="col-8">
                      <div className='row d-flex align-items-center justify-content-center'>
                        <div className='col-md-6 my-2'><img className="news-thumb rounded" src={`https://server.raketefreiburg.de/uploads/${newsItem.picture}`} alt={`${newsItem.title}`} /></div>
                        <div className='col-md-6 rakete-font text-center'>{newsItem.title}</div>
                      </div>
                    </div>
                    <div className='col-2'>
                      <TbEditCircle size={21} className='hover icon-edit mx-1 my-1' onClick={() => handleNewsEdit(newsItem._id)} />
                      <RiDeleteBinLine size={22} className='hover icons mx-1 my-1' onClick={() => setdeleteNews(newsItem._id)} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <NewsCreate show={createNews} onHide={() => setCreateNews(false)} />
        {deleteNews && <NewsDelete show={deleteNews} onHide={() => setdeleteNews(false)} id={deleteNews} />}
        <NewsDetail
          show={showNews}
          onHide={() => setShowNews(false)}
          news={news.find(item => item._id === selectedNewsId)}
        />
        <NewsEdit show={editNews} onHide={() => setEditNews(false)} news={news.find(item => item._id === selectedNewsId)} id={selectedNewsId}/>
      </div>
    </div>
  )
}

export default News;
