import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { RiLockPasswordLine } from "react-icons/ri";
import { TbEditCircle } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import MemberDelete from './memberDelete';
import { getRoleLabel, getTeamLabel, getRightsLabel } from '../../helpers/role';
import MemberEdit from './memberEdit';
import formattedDate from '../../helpers/formattedDate';
import { useAuth } from '../../provider/auth';
import MemberPassword from './memberPassword';
import { SlBadge } from "react-icons/sl";
import { IoDocumentTextOutline } from "react-icons/io5";
import axios from 'axios';
import Members from './members';

function MemberDetail({ show, onHide, member }) {
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { user, rights } = useAuth();

  useEffect(() => {
    if (showDelete) {
      setShowEdit(false);
      setShowPassword(false);
    }
    if (showEdit) {
      setShowDelete(false);
      setShowPassword(false);
    }
    if (showPassword) {
      setShowDelete(false);
      setShowEdit(false);
    }
  }, [showEdit, showDelete, showPassword]);

  if (!member) {
    return null;
  }

  const downloadApplication = (filename) => {
    window.open(`https://server.raketefreiburg.de/members/download/${filename}`, '_blank');
};

  const renderMemberDetails = () => (
    <div>
      <div className='row d-flex justify-content-center my-3'>
      <div className='col-auto border-end'>
          <div className='fs-8 text-secondary'>TEAM</div>
          <div className='fs-7 rakete-font fw-semibold text-uppercase text-secondary'>{getTeamLabel(member.team)}</div>
        </div>
        <div className='col-auto border-end'>
          <div className='fs-8 text-secondary'>FUNKTION</div>
          <div className='fs-7 rakete-font fw-semibold text-uppercase text-secondary'>{getRoleLabel(member.role)}</div>
        </div>
        <div className='col-auto border-end'>
          <div className='fs-8 text-secondary'>RECHTE</div>
          <div className='fs-7 rakete-font fw-semibold text-uppercase text-secondary'>{getRightsLabel(member.rights)}</div>
        </div>
        <div className='col-auto'>
          <div className='fs-8 text-secondary'>STATUS</div>
          <div className={member.active === true ? "fs-7 rakete-font fw-semibold text-success" : "fs-7 rakete-font fw-semibold text-warning"}>{member.active === true ? "AKTIV" : "PASSIV"}</div>
        </div>
        {member.special === true &&
          <div className='col-auto'>
            <SlBadge size={28} />
          </div>
        }

      </div>
      <div className=' row bg-light align-items-center py-2 border-top'>
        <div className="col-3 text-end fs-8 text-uppercase fw-semibold rakete-font text-secondary">Name</div>
        <div className="col-auto text-start">{member.forename} {member.lastname}</div>
      </div>
      <div className='row bg-light align-items-center py-2 border-top'>
        <div className="col-3 text-end fs-8 text-uppercase fw-semibold rakete-font text-secondary">Email</div>
        <div className="col-auto text-start">{member.email}</div>
      </div>
      <div className='row bg-light align-items-center py-2 border-top'>
        <div className="col-3 text-end fs-8 text-uppercase fw-semibold rakete-font text-secondary">Telefon</div>
        <div className="col-auto text-start">{member.phoneNumber || '-'}</div>
      </div>
      <div className='row bg-light align-items-center py-2 border-top'>
        <div className="col-3 text-end fs-8 text-uppercase fw-semibold rakete-font text-secondary">Adresse</div>
        <div className="col-auto text-start">
          <div>{member.address ? `${member.address.street} ${member.address.houseNumber},` : '-'}</div>
          <div>{member.address ? `${member.address.postalCode} ${member.address.city}` : ''}</div>
        </div>
      </div>
      <div className='row bg-light align-items-center py-2 border-top'>
        <div className="col-3 text-end fs-8 text-uppercase fw-semibold rakete-font text-secondary">Geburtstag</div>
        <div className="col-auto text-start">{formattedDate(member.birthday)}</div>
      </div>
      <div className='row bg-light align-items-center py-2 border-top border-bottom'>
        <div className="col-3 text-end fs-8 text-uppercase fw-semibold rakete-font text-secondary">Mitglied seit</div>
        <div className="col-auto text-start">{member.entryDate ? formattedDate(member.entryDate) : '-'}</div>
      </div>
    </div>
  );

  return (
    <Modal show={show} onHide={() => { onHide(); setShowEdit(false); setShowDelete(false); }} className="mx-auto" centered size="md">
      <Modal.Header className='border border-0 rakete-rot' closeButton>
        <Modal.Title className='rakete-font fw-semibold text-uppercase fs-6 '>{member.forename} {member.lastname}</Modal.Title>
      </Modal.Header>
      <Modal.Body className=' border-top'>
      {!showEdit && <img src={`https://server.raketefreiburg.de/uploads/${member.picture}`} className="profile-image" alt={`Profilbild: ${member.forename}`} />}
        {!(showEdit || showDelete) && renderMemberDetails()}
        {(user === member._id || rights === 2) && (
          <>
            <MemberEdit showEdit={showEdit} onHide={() => setShowEdit(false)} member={member} />
            <MemberDelete show={showDelete} onHide={() => setShowDelete(false)} id={member._id} />
            <MemberPassword show={showPassword} onHide={() => setShowPassword(false)} id={member._id} />
          </>
        )}
      </Modal.Body>
      <Modal.Footer className='border border-0 fs-8'>
        {(user === member._id || rights === 2) && (
          <>
            {rights === 2  && <IoDocumentTextOutline size={22} className={` icons mx-3 mt-1 hover ${member.documents?.available && member.documents?.path ? 'text-success' : (member.documents?.available ? "text-warning" : "text-danger")}`} onClick={member.documents?.path && (() => downloadApplication(member.documents.path) )} />}
            {user === member.id && <RiLockPasswordLine size={26} className='hover icons mx-3' title="Passwort zurücksetzen" onClick={() => { setShowPassword(!showPassword); setShowDelete(false); setShowEdit(false); }} />}
            <TbEditCircle size={25} className={` icon-edit mx-3 ${showEdit ? 'icon-edit-active' : 'hover'}`} onClick={() => { setShowEdit(!showEdit); setShowDelete(false); setShowPassword(false); }} />
            {rights === 2 && <RiDeleteBinLine size={24} className={` icons mx-3 ${showDelete ? 'icon-active' : 'hover'}`} onClick={() => { setShowDelete(!showDelete); setShowEdit(false); setShowPassword(false); }} />}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default MemberDetail;
