import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TbEditCircle } from "react-icons/tb";
import Accordion from 'react-bootstrap/Accordion';
import TeamsEdit from '../components/teams/TeamsEdit';

function Teams() {
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState({});

    const handleEdit = (team) => {
        setEdit(true);
        setSelectedTeam(team);
    }

    useEffect(() => {
        setLoading(true);
        axios.get(`/api/Teams`)
            .then((response) => {
                setTeams(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    return (
        <>
            <div className='container-fluid'>
                <div className='fw-semibold rakete-font fs-4 ms-3 text-start text-uppercase rakete-rot'>Teams</div>
                <Accordion defaultActiveKey={[0, 1, 2, 3]}>
                    <div className='container-fluid'>
                        <div className=" shadow-sm rounded my-3">
                            {teams && teams.map((team, index) => (
                                <Accordion.Item eventKey={index} key={index}>
                                    <Accordion.Header className='border border-0'>
                                        <div className='col-8 fw-semibold rakete-font fs-4 ms-3 text-uppercase'>{team.name}</div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className=' fs-7 mx-1 pb-2'>
                                            <div className='text-end'>
                                                <TbEditCircle size={25} className='hover icon-edit mx-3 my-3' onClick={() => handleEdit(team)} />
                                            </div>
                                            <div className='row d-flex justify-content-center'>
                                                <div className='col-md-5 text-center'>
                                                    <img src={`https://server.raketefreiburg.de/uploads/${team.picture}`} className="team-pic rounded card-img-top shadow-sm news-ratio" />
                                                </div>
                                                <div className='col-md-5 text-center'>
                                                    <p className="text-center rakete-font fs-4 rakete-rot fw-semibold">TRAINING</p>
                                                        {!team.trainings || team.trainings.length === 0 ? (
                                                            <p>Keine Trainingszeiten vorhanden</p>
                                                        ) : (
                                                            <div className='py-2'>
                                                                <div className='text-center fw-semibold rakete-font mb-2 fs-7' colspan="5">Sommer</div>
                                                                <table className="table table-striped">
                                                                    <thead className='text-center fs-8 rakete-font'>
                                                                        <th>Tag</th>
                                                                        <th>Uhrzeit</th>
                                                                        <th>Dauer</th>
                                                                        <th>Ort</th>
                                                                    </thead>
                                                                    <tbody className='text-center'>
                                                                        {team.trainings
                                                                            .filter(training => training.season)
                                                                            .sort((a, b) => {
                                                                                const weekdays = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
                                                                                return weekdays.indexOf(a.day) - weekdays.indexOf(b.day);
                                                                            })
                                                                            .map((training) => (
                                                                                <tr className="table fs-8">
                                                                                    <th>{training.day}</th>
                                                                                    <td>{training.time} Uhr</td>
                                                                                    <td>{training.duration} Std</td>
                                                                                    <td>{training.location}</td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                <div className='text-center fw-semibold rakete-font mb-2 fs-7' colspan="5">Winter</div>
                                                                <table className="table table-striped">
                                                                    <thead className='text-center fs-8 rakete-font text-uppercase'>
                                                                        <th>Tag</th>
                                                                        <th>Uhrzeit</th>
                                                                        <th>Dauer</th>
                                                                        <th>Ort</th>
                                                                    </thead>
                                                                    <tbody className='text-center'>
                                                                        {team.trainings
                                                                            .filter(training => !training.season)
                                                                            .sort((a, b) => {
                                                                                const weekdays = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
                                                                                return weekdays.indexOf(a.day) - weekdays.indexOf(b.day);
                                                                            })
                                                                            .map((training) => (
                                                                                <tr className="table fs-8">
                                                                                    <th>{training.day}</th>
                                                                                    <td>{training.time} Uhr</td>
                                                                                    <td>{training.duration} Std</td>
                                                                                    <td>{training.location}</td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        )}
                                                    <div className='text-center pt-3'>
                                                        <p className="text-center rakete-font fs-4 rakete-rot fw-semibold">ABOUT US</p>
                                                        {team.about}
                                                    </div>
                                                    <div className='text-center pt-3'>
                                                        <p className="text-center rakete-font fs-4 rakete-rot fw-semibold">MOTTO</p>
                                                        <p className='rakete-font fw-semibold'>{team.slogan}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <div className='row d-flex justify-content-center my-3'>

                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                        </div>
                    </div>
                    <TeamsEdit show={edit} onHide={() => setEdit(false)} team={selectedTeam} />
                </Accordion >
            </div>
        </>
    )
}

export default Teams;
