import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function MemberAdd({ show, onHide, setMembers }) {
    const [forename, setForename] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [rights, setRights] = useState(0);

    const closeMemberAdd = () => {
        onHide();
    }

    const handleAddMember = () => {
        const formData = new FormData();
        formData.append('forename', forename);
        formData.append('lastname', lastname);
        formData.append("email", email)
        formData.append("rights", rights)

        setLoading(true);

        axios
            .post("/members/save", formData)
            .then(() => {
                setLoading(false);
                onHide();
                setMembers(prevMembers => [...prevMembers, {
                    forename: forename,
                    lastname: lastname,
                    email: email,
                    rights: rights
                }]);
            })
            .catch((error) => {
                setLoading(false);
                alert("An error occured. Please check Console");
                console.log(error);
            });
    };

    return (
        <>
            <Modal show={show} onHide={closeMemberAdd} size="md">
                <Modal.Header className='rakete-font rakete-rot text-uppercase fw-semibold fs-5' closeButton onClick={closeMemberAdd}>Member hinzufügen</Modal.Header>
                <Modal.Body className='fs-7'>
                    <Row className="my-2">
                        <Form.Group as={Col}>
                            <Form.Label value="Vorname">Vorname</Form.Label>
                            <Form.Control
                                id="forename"
                                type="text"
                                className='form-control-sm'
                                value={forename}
                                onChange={(e) => setForename(e.target.value)}
                            />
                        </Form.Group >
                        <Form.Group as={Col}>
                            <Form.Label value="Nachname" >Nachname</Form.Label>
                            <Form.Control
                                id="lastname"
                                type="text"
                                className='form-control-sm'
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Form.Group className="my-2">
                        <Form.Label value="Email" >Email</Form.Label>
                        <Form.Control
                            id="email"
                            type="email"
                            className='form-control-sm'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="my-2 role">
                        <Form.Label value="Rolle" >Rechte</Form.Label>
                        <Form.Select
                            size="sm"
                            type="text"
                            className='form-control-sm'
                            value={rights}
                            onChange={(e) => setRights(e.target.value)}
                        >
                            <option value="0">User</option>
                            <option value="1">Operator</option>
                            <option value="2">Admin</option>
                        </Form.Select>
                    </Form.Group>
                </Modal.Body >
                <Modal.Footer>
                    <button className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7" onClick={handleAddMember} type="submit">Speichern</button>
                    <button className="text-center decline-button text-uppercase rounded fw-semibold fs-7" onClick={closeMemberAdd}>
                        Verwerfen
                    </button>
                </Modal.Footer>
            </Modal >
        </>

    );
};

export default MemberAdd;