import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { HiOutlinePlus } from "react-icons/hi";
import MemberDetail from './memberDetail';
import { getRightsLabel } from '../../helpers/role';
import MemberAdd from './memberAdd';
import { IoClose } from "react-icons/io5";
import VorstandAdd from './addVorstand';

function Members() {
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedMemberId, setSelectedMemberId] = useState(null);
    const [showMember, setShowMember] = useState(false);
    const [addMember, setAddMember] = useState(false);
    const [addVorstand, setAddVorstand] = useState(false);
    const [vorstand, setVorstand] = useState(0);
    const [number, setNumber] = useState(0);

    useEffect(() => {
        loadMembers();
    }, []);

    const loadMembers = () => {
        setLoading(true);
        axios
            .get('/members')
            .then((response) => {
                setMembers(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const handleMemberDetail = (id) => {
        setShowMember(true);
        setSelectedMemberId(id);
    };

    const handleAdd = (x, newIndex) => {
        setVorstand(x);
        setAddVorstand(true);
        setNumber(newIndex);
    };

    const handleDeleteVorstand = async (id) => {
        setLoading(true);
        try {
            await axios.put(`/members/role/delete/${id}`);
            loadMembers();
        } catch (error) {
            setLoading(false);
            console.error('Error deleting Vorstand:', error);
        }
    };

    function ListLayout({ index, members, position, value }) {
        const newIndex = index - value

        const member = members.find(member => member.role === index)

        if (member) {
            return (
                <div key={member._id} className=" newsline rounded-5 row py-2 d-flex align-items-center justify-content-center">
                    <div className='col-2 rakete-font fs-7 text-center mt-2'>{newIndex}.<p className='fs-8'>{position}</p></div>
                    <div className='col-2 my-2'><img className={`avatar-p`} src={`https://server.raketefreiburg.de/uploads/${member.picture}`} alt={`${member.forename}`} /></div>
                    <div className='col-6 fs-7 fw-semibold text-center'>{member.forename} {member.lastname}</div>
                    <div className='col-1' onClick={() => handleDeleteVorstand(member._id)} ><IoClose size={22} className='icon-edit' /></div>
                </div>
            )
        } else {
            return (
                <div className="newsline add-button rounded-5 row py-3 d-flex align-items-center justify-conent-center">
                    <div className='col-2 rakete-font fs-7 text-center mt-2'>{newIndex}.</div>
                    <div className='col-8 my-1 rakete-font text-uppercase' onClick={() => handleAdd(index, newIndex)}><HiOutlinePlus size={20} className='mx-4' /><br />Hinzufügen</div>
                </div>
            )

        }
    };

    return (
        <div className='container-fluid'>
            <div className='row justify-content-center d-flex'>
                <div className='col-lg-4'>
                    <div className="px-3 shadow-sm rounded my-3">
                        <div className='row d-flex align-items-center'>
                            <div className='col-9'><p className='fw-semibold rakete-font rakete-rot fs-3 mt-3 ms-3 text-start text-uppercase'>Mitglieder</p></div>
                            <div className='col-3 text-end'><HiOutlinePlus size={28} className='mx-4 hover icon-edit' onClick={() => setAddMember(true)} /></div>
                        </div>
                        <div className=' fs-7 mx-1 pb-2'>
                            <div className="text-center">
                                {members.map((member, index) => (
                                    <div key={member._id} className=" newsline rounded-5 row py-2 d-flex align-items-center justify-content-center" onClick={() => handleMemberDetail(member._id)}>
                                        <div className='col-1 rakete-font'>-</div>
                                        <div className='col-2 my-2'><img className={`avatar-p`} src={`https://server.raketefreiburg.de/uploads/${member.picture}`} alt={`${member.forename}`} /></div>
                                        <div className='col-6 fs-7 text-center fw-semibold'>{member.forename} {member.lastname}</div>
                                        <div className={`col-3 fs-8 text-uppercase rakete-font ${getRightsLabel(member.rights)}`}>
                                            {getRightsLabel(member.rights)}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="px-3 shadow-sm rounded my-3">
                        <p className='fw-semibold rakete-font rakete-rot fs-3 mt-3 ms-3 text-start text-uppercase'>Vorstand</p>

                        <div className=' fs-7 mx-1 pb-2'>
                            <div className="text-center">
                                <ListLayout index={1} members={members} position={"Vorstand"} value={0} />
                                <ListLayout index={2} members={members} position={"Vorstand"} value={0} />
                                <ListLayout index={3} members={members} position={"Vorstand"} value={0} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-8'>
                    <div className="my-3">
                        <p className='fw-semibold rakete-font rakete-rot fs-3 pt-3 ms-3 text-start text-uppercase'>Leitung</p>
                        <div className='row fs-7 mx-1 px-2 pb-2 text-center shadow-sm rounded-3'>
                            <div className='col-lg-6'>
                                <div className='text-start text-uppercase rakete-font fw-semibold ms-4 mt-3 mb-2'>Erste</div>
                                <ListLayout index={4} members={members} value={3} />
                                <ListLayout index={5} members={members} value={3} />
                            </div>
                            <div className='col-lg-6 mt-3 mb-2'>
                                <img src="https://placehold.co/200x300@3x.png" className='rounded img-leitung' />
                            </div>
                        </div>
                        <div className='row fs-7 mx-1 px-2 pb-2 text-center shadow-sm rounded-3 mt-3'>
                            <div className='col-lg-6'>
                                <div className='text-start text-uppercase rakete-font fw-semibold ms-4 mt-3 mb-2'>Zweite</div>
                                <ListLayout index={6} members={members} value={5} />
                                <ListLayout index={7} members={members} value={5} />
                            </div>
                            <div className='col-lg-6 mt-3 mb-2'>
                                <img src="https://placehold.co/200x300@3x.png" className='rounded img-leitung' />
                            </div>
                        </div>
                        <div className='row fs-7 mx-1 px-2 pb-2 text-center shadow-sm rounded-3 mt-3'>
                            <div className='col-lg-6'>
                                <div className='text-start text-uppercase rakete-font fw-semibold ms-4 mt-3 mb-2'>Mixed</div>
                                <ListLayout index={8} members={members} value={7} />
                                <ListLayout index={9} members={members} value={7} />
                            </div>
                            <div className='col-lg-6 mt-3 mb-2'>
                                <img src="https://placehold.co/200x300@3x.png" className='rounded img-leitung' />
                            </div>
                        </div>
                        <div className='row fs-7 mx-1 px-2 pb-2 text-center shadow-sm rounded-3 mt-3'>
                            <div className='col-lg-6'>
                                <div className='text-start text-uppercase rakete-font fw-semibold ms-4 mt-3 mb-2'>Frauen</div>
                                <ListLayout index={10} members={members} value={9} />
                                <ListLayout index={11} members={members} value={9} />
                            </div>
                            <div className='col-lg-6 mt-3 mb-2'>
                                <img src="https://placehold.co/200x300@3x.png" className='rounded img-leitung' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <VorstandAdd show={addVorstand} onHide={() => setAddVorstand(false)} members={members} role={vorstand} index={number} />
            <MemberDetail show={showMember} onHide={() => setShowMember(false)} member={members.find(item => item._id === selectedMemberId)} />
            <MemberAdd show={addMember} onHide={() => setAddMember(false)} setMembers={setMembers} />
        </div>
    )
}

export default Members;
