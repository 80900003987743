import React, { useState, useEffect } from 'react';
import axios from 'axios';

import getRoleLabel from '../../helpers/role';
import OpponentAdd from './OpponentAdd';
import { IoClose } from "react-icons/io5";
import { TbEditCircle } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaCheck } from "react-icons/fa6";
import { HiOutlinePlus } from "react-icons/hi";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import OpponentDelete from './OpponentDelete';

function Opponents({opponents, onChange}) {
    const [loading, setLoading] = useState(false);
    const [editOppIndex, setEditOppIndex] = useState(-1);
    const [addOpponent, setAddOpponent] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [deleteOpponentId, setDeleteOpponentId] = useState("");
    const [newName, setNewName] = useState("");
    const [newShort, setNewShort] = useState("");

    const handleEditOpponent = (index) => {
        setEditOppIndex(index);
        const opponentToEdit = opponents[index]; // Gegner, der bearbeitet werden soll
        setNewName(opponentToEdit.name); // Setze neuen Namen
        setNewShort(opponentToEdit.short); // Setze neues Kürzel
    };

    const handleDeleteOpponent = (id) => {
        console.log(id);
        setDeleteOpponentId(id);
        setShowDelete(true)
    }

    const refreshOpponents = (refresh) => {
        setAddOpponent(false);
        setShowDelete(false);
        if(refresh === true) {
            onChange();
        }
    }

    const handleSaveOpponent = (id) => {
        const formData = {
            name: newName,
            short: newShort,
        }
        setEditOppIndex(-1);

        setLoading(true);
        axios
            .put(`/api/Opponents/${id}`, formData)
            .then(() => {
                setLoading(false);
                onChange();
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    return (
        <Accordion defaultActiveKey={null}>
            <div className='container-fluid'>
                <div className='justify-content-center'>
                    <div className="px-3 shadow-sm rounded my-3">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className='border border-0'>
                                <div className='fw-semibold rakete-font fs-5 ms-3 text-start text-uppercase'>Gegner</div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className='text-end mb-3'><HiOutlinePlus size={28} className='mx-1 hover icon-edit' onClick={() => setAddOpponent(true)} /></div>
                                <div className=' fs-7 mx-1 pb-2'>
                                    <div className="text-center">
                                        {opponents && opponents.map((opponent, index) => (
                                            <div key={opponent._id} className=" newsline rounded-5 row py-2 d-flex align-items-center justify-content-center">
                                                <div className='col-2 my-2'><img className={`opp-logo`} src={`https://server.raketefreiburg.de/uploads/${opponent.logo}`} alt={`${opponent.name}`} /></div>
                                                {editOppIndex === index ? (
                                                    <>
                                                        <div className='col'>
                                                            <Form as={Row} className='text-cursor'>
                                                                <Form.Group as={Col} xs={4}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        className='form-control-sm'
                                                                        value={newShort}
                                                                        onChange={(e) => setNewShort(e.target.value)}
                                                                    />
                                                                </Form.Group >
                                                                <Form.Group as={Col} xs={8}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        className='form-control-sm'
                                                                        value={newName}
                                                                        onChange={(e) => setNewName(e.target.value)}
                                                                    />
                                                                </Form.Group >
                                                            </Form>
                                                        </div>
                                                        <div className="col-3 text-center">
                                                            <FaCheck size={19} className='hover icon-edit mx-1 my-1 check' onClick={() => handleSaveOpponent(opponent._id)} />
                                                            <IoClose size={22} className='hover icons mx-1 my-1 decline ' onClick={() => setEditOppIndex(-1)} />
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="col-1 rakete-font fw-semibold mx-2">{opponent.short}</div>
                                                        <div className="col-4 ms-1 text-start text-wrap">{opponent.name}</div>

                                                        <div className="col-4 text-center">
                                                            <TbEditCircle size={21} className='hover icon-edit mx-1 my-1' onClick={() => handleEditOpponent(index)} />
                                                            <RiDeleteBinLine size={22} className='hover icons mx-1 my-1' onClick={() => handleDeleteOpponent(opponent._id)} />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </div>
                </div>
                <OpponentAdd show={addOpponent} onHide={refreshOpponents}/>
                <OpponentDelete show={showDelete} id={deleteOpponentId} onHide={refreshOpponents} />
            </div>
        </Accordion >
    )
}

export default Opponents;
