import React, { useState} from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

function GameAdd({ show, onHide, opponents}) {
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState("");
    const [location, setLocation] = useState('');
    const [opp, setOpp] = useState('');
    const [loading, setLoading] = useState(false);

    const closeGameAdd = () => {
        onHide();
    }

    const handleAddGame = () => {
        const gameData = {
            date: date,
            location: location,
            opponent: opp
        };

        setLoading(true);

        axios
            .post("/api/Games", gameData)
            .then(() => {
                setLoading(false);
                onHide();
                window.location.reload();
            })
            .catch((error) => {
                setLoading(false);
                alert("An error occurred. Please check Console");
                console.log(error);
            });
    };

    return (
        <>
            <Modal show={show} onHide={closeGameAdd} size="md">
                <Modal.Header className='rakete-font rakete-rot text-uppercase fw-semibold fs-5' closeButton onClick={closeGameAdd}>Neues Spiel</Modal.Header>
                <Modal.Body className='fs-7'>
                    <Form.Group>
                        <Form.Label className="rakete-font rakete-rot text-uppercase fs-8 me-3 mt-4">Datum</Form.Label>
                        <DateTimePicker
                            selected={date}
                            onChange={(newDate) => setDate(newDate)}
                        />
                    </Form.Group>
                    <Form.Group className="my-2 mt-4 mb-3">
                        <Form.Label className="rakete-font rakete-rot text-uppercase fs-8" >Gegner</Form.Label>
                        <Form.Select
                            size="sm"
                            type="text"
                            className='form-control-sm'
                            value={opp}
                            onChange={(e) => setOpp(e.target.value)}
                        >   <option>Wähle einen Gegner aus...</option>
                            {opponents && opponents.map((opponent, index) => (
                                <option key={opponent._id} value={opponent._id}>{opponent.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="rakete-font rakete-rot text-uppercase fs-8">Ort</Form.Label>
                        <Form.Control
                            id="location"
                            type="text"
                            className='form-control-sm'
                            value={location}
                            onChange={(e) => {
                                setLocation(e.target.value);
                            }}
                        />
                    </Form.Group >
                </Modal.Body >
                <Modal.Footer>
                    <button className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7" onClick={handleAddGame} type="submit">Speichern</button>
                    <button className="text-center decline-button text-uppercase rounded fw-semibold fs-7" onClick={closeGameAdd}>
                        Verwerfen
                    </button>
                </Modal.Footer>
            </Modal >
        </>

    );
};

export default GameAdd;
