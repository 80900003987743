import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';

function MemberDelete({ show, id }) {
  const [loading, setLoading] = useState(false);

  const handleMemberDelete = () => {
    setLoading(true);
    axios
      .delete(`/members/${id}`)
      .then(() => {
        setLoading(false);
        window.location.reload();
        
      })
      .catch(error => {
        setLoading(false);
        console.error('Error deleting Member:', error);
      });
  };

  if (!show) {
    return null
  } else {
    return (
      <div className='mt-2 mx-2 text-center'>
        Bist du dir sicher, dass du diesen User löschen möchtest?
        <div className='pt-3 div-justify-middle'>
          <button onClick={handleMemberDelete} className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7 mx-1" disabled={loading}>Löschen</button>
        </div>
      </div>
    );
  }
}
export default MemberDelete;