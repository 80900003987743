import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function MemberAdd({ show, onHide, setMembers }) {
    const [forename, setForename] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [street, setStreet] = useState('');
    const [houseNumber, setHouseNumber] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [entryDate, setEntryDate] = useState('');
    const [birthday, setBirthday] = useState('');
    const [role, setRole] = useState('');
    const [rights, setRights] = useState();
    const [user, setUser] = useState(false);
    const [active, setActive] = useState(true);
    const [special, setSpecial] = useState(false);
    const [loading, setLoading] = useState(false);
    const [team, setTeam] = useState();
    const [gender, setGender] = useState(''); // Neues Feld für Geschlecht
    const [documentsAvailable, setDocumentsAvailable] = useState(false); // Neues Feld für Mitgliedsantrag-Bestätigung
    const [memberFile, setMemberFile] = useState(null); // Neues Feld für den Mitgliedsantrag-Upload

    const closeMemberAdd = () => {
        setForename('');
        setLastname('');
        setEmail('');
        setPhoneNumber('');
        setStreet('');
        setHouseNumber('');
        setCity('');
        setPostalCode('');
        setEntryDate('');
        setRole('');
        setRights(0);
        setTeam();
        setUser(false);
        setActive(true);
        setSpecial(false);
        setGender('');
        setDocumentsAvailable(false);
        setMemberFile(null);
        onHide();
        setBirthday("");
    };

    const handleAddMember = () => {
        const formData = new FormData();
        formData.append('forename', forename);
        formData.append('lastname', lastname);
        formData.append('email', email);
        formData.append('phoneNumber', phoneNumber);
        formData.append('street', street);
        formData.append('houseNumber', houseNumber);
        formData.append('city', city);
        formData.append('team', team);
        formData.append('postalCode', postalCode);
        formData.append('entryDate', entryDate);
        formData.append('role', role);
        formData.append('rights', rights);
        formData.append('birthday', birthday);
        formData.append('user', user); // User Account
        formData.append('active', active); // Aktiv/Passiv Zustand
        formData.append('special', special); // Ehren- oder Fördermitglied Zustand
        formData.append('gender', gender); // Geschlecht
        formData.append('documentsAvailable', documentsAvailable); // Bestätigung für Mitgliedsantrag

        // Mitgliedsantrag-Upload
        if (memberFile) {
            formData.append('memberFile', memberFile);
        }

        setLoading(true);

        axios
            .post("/members/save", formData)
            .then(() => {
                setLoading(false);
                onHide();
                setMembers(prevMembers => [...prevMembers, {
                    forename: forename,
                    lastname: lastname,
                    email: email,
                    phoneNumber: phoneNumber,
                    address: {
                        street: street,
                        houseNumber: houseNumber,
                        city: city,
                        postalCode: postalCode
                    },
                    entryDate: entryDate,
                    role: role,
                    rights: rights,
                    team: team,
                    user: user,
                    active: active,
                    special: special,
                    birthday: birthday,
                    gender: gender,
                    documents: {
                        available: documentsAvailable,
                        file: memberFile ? memberFile.name : null
                    }
                }]);
            })
            .catch((error) => {
                setLoading(false);
                alert("Ein Fehler ist aufgetreten. Bitte prüfe die Konsole");
                console.log(error);
            });
    };


    return (
        <>
            <Modal show={show} onHide={closeMemberAdd} size="md" className='mx-auto'>
                <Modal.Header className='rakete-font rakete-rot text-uppercase fw-semibold fs-5' closeButton onClick={closeMemberAdd}>
                    Mitglied hinzufügen
                </Modal.Header>
                <Modal.Body className='fs-7'>
                    <Form.Group className="my-2">
                        <Form.Label>Geschlecht</Form.Label>
                        <Form.Select
                            size="sm"
                            className='form-control-sm'
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                        >
                            <option value="">Geschlecht wählen</option>
                            <option value="0">Mann</option>
                            <option value="1">Frau</option>
                            <option value="2">Divers</option>
                        </Form.Select>
                    </Form.Group>

                    <Row className="my-2">
                        <Form.Group as={Col}>
                            <Form.Label>Vorname</Form.Label>
                            <Form.Control
                                id="forename"
                                type="text"
                                className='form-control-sm'
                                value={forename}
                                onChange={(e) => setForename(e.target.value)}
                            />
                        </Form.Group >
                        <Form.Group as={Col}>
                            <Form.Label>Nachname</Form.Label>
                            <Form.Control
                                id="lastname"
                                type="text"
                                className='form-control-sm'
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Form.Group className="my-2">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            id="email"
                            type="email"
                            className='form-control-sm'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="my-2">
                        <Form.Label>Telefonnummer</Form.Label>
                        <Form.Control
                            id="phoneNumber"
                            type="text"
                            className='form-control-sm'
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </Form.Group>
                    <Row className="my-2">
                        <Form.Group as={Col}>
                            <Form.Label>Straße</Form.Label>
                            <Form.Control
                                id="street"
                                type="text"
                                className='form-control-sm'
                                value={street}
                                onChange={(e) => setStreet(e.target.value)}
                            />
                        </Form.Group >
                        <Form.Group as={Col} xs={3}>
                            <Form.Label>Nr.</Form.Label>
                            <Form.Control
                                id="houseNumber"
                                type="text"
                                className='form-control-sm'
                                value={houseNumber}
                                onChange={(e) => setHouseNumber(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Row className="my-2">
                        <Form.Group as={Col} xs={5}>
                            <Form.Label>Postleitzahl</Form.Label>
                            <Form.Control
                                id="postalCode"
                                type="text"
                                className='form-control-sm'
                                value={postalCode}
                                onChange={(e) => setPostalCode(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Stadt</Form.Label>
                            <Form.Control
                                id="city"
                                type="text"
                                className='form-control-sm'
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                            />
                        </Form.Group >
                    </Row>
                    <Form.Group className="my-2">
                        <Form.Label>Geburtstag</Form.Label>
                        <Form.Control
                            id="birthday"
                            type="date"
                            className='form-control-sm'
                            value={birthday}
                            onChange={(e) => setBirthday(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="my-2">
                        <Form.Label>Team</Form.Label>
                        <Form.Select
                            size="sm"
                            type="text"
                            className='form-control-sm'
                            value={team}
                            onChange={(e) => setTeam(e.target.value)}
                        >
                            <option value="">Team wählen</option>
                            <option value="0">Erste</option>
                            <option value="1">Zweite</option>
                            <option value="2">Mixed</option>
                            <option value="3">Frauen</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="my-2">
                        <Form.Label>Eintrittsdatum</Form.Label>
                        <Form.Control
                            id="entryDate"
                            type="date"
                            className='form-control-sm'
                            value={entryDate}
                            onChange={(e) => setEntryDate(e.target.value)}
                        />
                    </Form.Group>
                    <Row className="my-2">
                        <Form.Group className="my-2 role" as={Col}>
                            <Form.Label>Rechte</Form.Label>
                            <Form.Select
                                size="sm"
                                type="text"
                                className='form-control-sm'
                                value={rights}
                                onChange={(e) => setRights(e.target.value)}
                            >
                                <option value="0">Keine</option>
                                <option value="1">Operator</option>
                                <option value="2">Admin</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="my-2 role" as={Col}>
                            <Form.Label>Rolle</Form.Label>
                            <Form.Select
                                size="sm"
                                type="text"
                                className='form-control-sm'
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                            >
                                <option value="">Wähle eine Rolle</option>
                                <option value="0">Keine</option>
                                <option value="1">1. Vorstand</option>
                                <option value="2">2. Vorstand</option>
                                <option value="3">3. Vorstand</option>
                                <option value="4">Leitung Erste</option>
                                <option value="5">Leitung Zweite</option>
                                <option value="6">Leitung Mixed</option>
                                <option value="7">Leitung Frauen</option>
                                <option value="8">Bunte - Liga Koordinator</option>
                                <option value="9">Turnier - Team</option>
                                <option value="10">Webmaster</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>

                    <Form.Group className="my-2">
                        <Form.Check
                            type="switch"
                            label={active === true ? "Aktiv" : "Passiv"}
                            name="activeStatus"
                            value={active}
                            checked={active === true}
                            onChange={() => setActive(!active)}
                        />
                    </Form.Group>
                    <Form.Group className="my-2">
                        <Form.Label>Ehren- oder Fördermitglied?</Form.Label>
                        <Form.Check
                            type="switch"
                            label={special === true ? "Ja" : "Nein"}
                            name="specialStatus"
                            value={special}
                            checked={special === true}
                            onChange={() => setSpecial(!special)}
                        />
                    </Form.Group>
                    <Form.Group className="my-2">
                        <Form.Label>User - Account erstellen?</Form.Label>
                        <Form.Check
                            type="switch"
                            label={user === true ? "Ja" : "Nein"}
                            checked={user}
                            onChange={(e) => setUser(e.target.checked)}
                        />
                    </Form.Group>

                    {/* Mitgliedsantrag hochladen */}
                    <Form.Group className="my-2">
                        <Form.Label>Antrag vorhanden?</Form.Label>
                        <Form.Check
                            type="switch"
                            label={documentsAvailable ? "Antrag bestätigt" : "Antrag fehlt"}
                            checked={!!documentsAvailable}
                            onChange={() => setDocumentsAvailable(!documentsAvailable)}
                        />

                    </Form.Group>
                    {documentsAvailable === true &&
                        <Form.Group className="my-2">
                            <Form.Control
                                id="memberFile"
                                name="memberFile"
                                type="file"
                                accept=".pdf, .jpeg, .jpg, .png"
                                className='form-control-sm'
                                onChange={(e) => setMemberFile(e.target.files[0])}
                            />
                        </Form.Group>
                    }
                </Modal.Body >
                <Modal.Footer>
                    <button className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7" onClick={handleAddMember} type="submit">Speichern</button>
                    <button className="text-center decline-button text-uppercase rounded fw-semibold fs-7" onClick={closeMemberAdd}>
                        Verwerfen
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default MemberAdd;
