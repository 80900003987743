import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';


function NewsDetail({ show, onHide, news }) {
    const [loading, setLoading] = useState(false);
    if (!news) {
      return null;
    }
  
    const formattedDate = (date) => {
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      };
      return new Date(date).toLocaleDateString('de-DE', options);
    };
  
    return (
      <Modal size="lg" show={show} onHide={onHide} centered>
        <Modal.Header className='bg-light justify-content-center border border-0 rakete-rot' closeButton>
          <Modal.Title className='rakete-font fw-semibold text-uppercase'>{news.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-light shadow'>
          <img src={`https://server.raketefreiburg.de/uploads/${news.picture}`} className="img-fluid rounded shadow-sm border" alt={`News: ${news.title}`} />
          <p className="fs-7 fw-semibold mx-4 text-end mt-2">{formattedDate(news.date)}</p>
          <p className="mx-3 mb-4 mt-2 fs-6">{news.content}</p>
        </Modal.Body>
        <Modal.Footer className='border border-0 fs-7'>Written by {news.author}</Modal.Footer>
      </Modal>
    );
  }

  export default NewsDetail;