import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import axios from "axios";
import { useAuth } from '../../provider/auth';

function MemberEdit({ showEdit, member }) {
    const [forename, setForename] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [rights, setRights] = useState(0);
    const [loading, setLoading] = useState(false);
    const [preview, setPreview] = useState();
    const [picture, setPicture] = useState("");
    const [newPicture, setNewPicture] = useState("");

    const { user, logout, rights: right } = useAuth();

    function handleChange(event) {
        setNewPicture(event.target.files[0]);
        setPreview(URL.createObjectURL(event.target.files[0]));
    }

    useEffect(() => {
        if (member) {
            setForename(member.forename);
            setLastname(member.lastname);
            setEmail(member.email);
            setRights(member.rights);
            setPicture(member.picture);
        }
    }, [member]);

    const handleEditMember = () => {
        const formData = new FormData();
        formData.append("forename", forename);
        formData.append("lastname", lastname);
        formData.append("email", email);
        formData.append("rights", rights);
        if (newPicture) {
            formData.append("picture", newPicture);
        } else {
            formData.append("picture", picture)
        }

        setLoading(true);

        axios
            .put(`/members/${member._id}`, formData)
            .then(() => {
                setLoading(false);
                setPreview();
                window.location.reload();
            })
            .catch((error) => {
                setLoading(false);
                alert("An error occured. Please check Console");
                console.log(error);
            });

    };


    if ((!member && showEdit) || (member && !showEdit)) {
        return null
    }

    return (
        <div>
            {(user === member._id) && (
                <>
                    {(!preview) ? (
                        <img src={`https://server.raketefreiburg.de/uploads/${picture}`} className="profile-image" alt={`News: ${member.forename}`} />
                    ) : (
                        <img src={preview} className="profile-image" />
                    )}
                </>
            )}

            <Row className="my-2">
                {(user === member._id) && (
                    <div id="fileUpload">
                        <div className="mb-2 block">
                            <Form.Label value="Bild" className='fs-8 text-uppercase rakete-rot rakete-font'>Bild</Form.Label>
                        </div>
                        <Form.Control className="form-control-sm" id="file" type="file" accept="image/*" onChange={handleChange} />
                    </div>
                )}
                {(right === 2) && (
                    <>
                        <Form.Group as={Col}>
                            <Form.Label value="Vorname" className='fs-8 text-uppercase rakete-rot rakete-font'>Vorname</Form.Label>
                            <Form.Control
                                id="forename"
                                type="text"
                                className='form-control-sm'
                                value={forename}
                                onChange={(e) => setForename(e.target.value)}
                            />
                        </Form.Group >
                        <Form.Group as={Col}>
                            <Form.Label value="Nachname" className='fs-8 text-uppercase rakete-rot rakete-font' >Nachname</Form.Label>
                            <Form.Control
                                id="lastname"
                                type="text"
                                className='form-control-sm'
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                            />
                        </Form.Group>
                    </>
                )}
            </Row>
            <Form.Group className="my-2">
                <Form.Label value="Email" className='fs-8 text-uppercase rakete-rot rakete-font' >Email</Form.Label>
                <Form.Control
                    id="email"
                    type="email"
                    className='form-control-sm'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </Form.Group>
            {(right === 2 && user != member._id) && (
                <Form.Group className="my-2 role">
                    <Form.Label className='fs-8 text-uppercase rakete-rot rakete-font'>Rolle</Form.Label>
                    <Form.Select
                        size="sm"
                        className='form-control-sm'
                        value={rights}
                        onChange={(e) => setRights(e.target.value)}
                    >
                        <option value="0">User</option>
                        <option value="1">Operator</option>
                        <option value="2">Admin</option>
                    </Form.Select>
                </Form.Group>
            )}
            <div className='pt-3 div-justify-middle'>
                <button className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7 mx-1" type="button" onClick={handleEditMember}>Speichern</button>
            </div>
        </div>
    );
}

export default MemberEdit;