function getRoleLabel(role) {
if(!role){
  return "Member";
};
if(role === 1 || role === 2 || role === 3) {
  return "Vorstand";
};
if(role === 4 || role === 5) {
  return "Leitung Erste";
};
if(role === 6 || role === 7) {
  return "Leitung Zweite";
};
if(role === 8 || role === 9) {
  return "Leitung Mixed";
};
if(role === 10 || role === 11) {
  return "Leitung Frauen";
};
}



function getRightsLabel(rights) {
  switch (rights) {
    case 0:
      return "user";
    case 1:
      return "operator";
    case 2:
      return "admin";
  }
}

export {getRoleLabel, getRightsLabel};