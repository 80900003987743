import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IoClose } from "react-icons/io5";
import { TbEditCircle } from "react-icons/tb";
import { FaCheck } from "react-icons/fa6";
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

function Freizeitkick() {
    const [training, setTraining] = useState({});
    const [editIndex, setEditIndex] = useState(-1);
    const [name, setName] = useState("");
    const [day, setDay] = useState("");
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [location, setLocation] = useState("");


    useEffect(() => {
        axios.get(`/api/Freizeitkick`)
            .then((response) => {
                const firstItem = response.data.data[0]; // Direkter Zugriff auf den ersten Eintrag
                setTraining(firstItem);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const handleEdit = (index) => {
        setEditIndex(1);
        setName(training.name);
        setDay(training.day);
        setStart(training.start);
        setEnd(training.end);
        setLocation(training.location);
    };

    const handleSave = (id) => {
        const formData = {
            name: name,
            day: day,
            start: start,
            end: end,
            location: location
        }

        setEditIndex(-1);
        axios
            .put(`api/Freizeitkick/${id}`, formData)
            .then(() => {
                window.location.reload();
            })
            .catch((error) => {
                console.log(error);
            });
    };



    return (
        <div className='container-fluid my-3'>
            <div className='shadow-sm rounded py-3'>
                <p className='fw-semibold rakete-font rakete-rot fs-4 text-start text-uppercase ms-2'>Gemeinsames Training</p>
                <div className="rounded-5 py-2">
                    {editIndex === 1 ? (
                        <Row className="my-2 justify-content-center align-items-center carot-auto mx-3">
                            <div className='col'>
                                <div className='row justify-content-center align-items-center text-center'>
                                    <Form.Group className='col-md-3'>
                                        <Form.Label value="Name" className='rakete-rot fs-7 rakete-font text-uppercase'>Name</Form.Label>
                                        <Form.Control
                                            id="name"
                                            type="text"
                                            className='form-control-sm'
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </Form.Group >
                                    <Form.Group className='col-md-2'>
                                        <Form.Label value="Day" className='rakete-rot fs-7 rakete-font text-uppercase'>Tag</Form.Label>
                                        <Form.Control
                                            id="day"
                                            type="text"
                                            className='form-control-sm'
                                            value={day}
                                            onChange={(e) => setDay(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group className=" col-md-2">
                                        <Form.Label value="start" className='rakete-rot fs-7 rakete-font text-uppercase'>Von</Form.Label>
                                        <Form.Control
                                            id="start"
                                            type="text"
                                            value={start}
                                            className='form-control-sm'
                                            onChange={(e) => setStart(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="col-md-2">
                                        <Form.Label value="end" className='rakete-rot fs-7 rakete-font text-uppercase'>Bis</Form.Label>
                                        <Form.Control
                                            id="end"
                                            type="text"
                                            value={end}
                                            className='form-control-sm'
                                            onChange={(e) => setEnd(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="col-lg-3">
                                        <Form.Label value="location" className='rakete-rot fs-7 rakete-font text-uppercase'>Ort</Form.Label>
                                        <Form.Control
                                            id="end"
                                            type="text"
                                            value={location}
                                            className='form-control-sm'
                                            onChange={(e) => setLocation(e.target.value)}
                                        />
                                    </Form.Group>
                                </div>

                            </div>
                            <div className="col-lg-1 text-center my-3 pt-4">
                                <FaCheck size={25} className='hover icon-edit mx-2 my-1 check' onClick={() => handleSave(training._id)} />
                                <IoClose size={28} className='hover icons mx-2 my-1 decline ' onClick={() => setEditIndex(-1)} />
                            </div>
                        </Row>
                    ) : (
                        <div className='row d-flex justify-content-center align-items-center text-center my-3'>
                            <div className='col-md-3 rakete-font'>{training.name}</div>
                            <div className='col-md-2 fw-semibold'>{training.day}</div>
                            <div className='col-md-2 '>{training.start} - {training.end} Uhr</div>
                            <div className='col-md'>{training.location}</div>
                            <div className="col-sm-1 text-end">
                                <TbEditCircle size={21} className='hover icon-edit me-5' onClick={() => handleEdit(1)} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Freizeitkick;