import React from 'react'
import Messages from '../components/messages'
import Tasks from '../components/tasks'
import Statistics from '../components/statistics'

const Dashboard = () => {
  return (
    <>
      <div className=' rakete-font rakete-rot fs-4 fw-semibold py-3 ms-3'>DASHBOARD</div>
      <div className='row mx-2'>
        <div className='col-md-6'>
          <Messages />
        </div>
        <div className='col-md-6'>
          <Tasks />
        </div>
      </div>
      <div className=' rakete-font fs-4 fw-semibold py-3 ms-3 text-center rounded shadow-sm mx-3 my-3'>
        STATISTIK
        <div>
          <Statistics/>
        </div>
        </div>
    </>
  )
}

export default Dashboard