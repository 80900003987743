import React, { useState, useEffect } from 'react';
import History from '../components/history/History';
import Freizeitkick from '../components/freizeitkick';

function Club() {

    return (<>
    <History/>
    <Freizeitkick/>
    </>
    )
}

export default Club;
