import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

function MeetingAdd({ show, onHide }) {
    const [date, setDate] = useState(new Date());
    const [location, setLocation] = useState('');
    const [opp, setOpp] = useState('');
    const [loading, setLoading] = useState(false);

    const closeMeetingAdd = () => {
        onHide();
    }

    const handleAddMeeting = () => {
        const meetingData = {
            date: date,
            location: location,
        };

        setLoading(true);

        axios
            .post("/api/Events", meetingData)
            .then(() => {
                setLoading(false);
                onHide();
                window.location.reload();
            })
            .catch((error) => {
                setLoading(false);
                alert("An error occurred. Please check Console");
                console.log(error);
            });
    };

    return (
        <>
            <Modal show={show} onHide={closeMeetingAdd} size="md">
                <Modal.Header className='rakete-font rakete-rot text-uppercase fw-semibold fs-5' closeButton onClick={closeMeetingAdd}>Neues Spiel</Modal.Header>
                <Modal.Body className='fs-7'>
                    <Form.Group>
                        <Form.Label className="rakete-font rakete-rot text-uppercase fs-8 me-3 mt-4">Datum</Form.Label>
                        <DateTimePicker
                            selected={date}
                            onChange={(newDate) => setDate(newDate)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="rakete-font rakete-rot text-uppercase fs-8">Ort</Form.Label>
                        <Form.Control
                            id="location"
                            type="text"
                            className='form-control-sm'
                            value={location}
                            onChange={(e) => {
                                setLocation(e.target.value);
                            }}
                        />
                    </Form.Group >
                </Modal.Body >
                <Modal.Footer>
                    <button className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7" onClick={handleAddMeeting} type="submit">Speichern</button>
                    <button className="text-center decline-button text-uppercase rounded fw-semibold fs-7" onClick={closeMeetingAdd}>
                        Verwerfen
                    </button>
                </Modal.Footer>
            </Modal >
        </>

    );
};

export default MeetingAdd;
