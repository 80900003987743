import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IoClose } from "react-icons/io5";
import { TbEditCircle } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaCheck } from "react-icons/fa6";
import { HiOutlinePlus } from "react-icons/hi";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import MeetingAdd from './MeetingAdd';
import MeetingDelete from './MeetingDelete';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

function Meetings() {
    const [meetings, setMeetings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editMeetingIndex, setEditMeetingIndex] = useState(-1);
    const [addMeeting, setAddMeeting] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [deleteMeetingId, setDeleteMeetingId] = useState("");
    const [date, setDate] = useState(new Date());
    const [location, setLocation] = useState("");

    useEffect(() => {
        setLoading(true);
        axios.get(`/api/Events`)
            .then((response) => {
                setMeetings(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    const handleEditMeeting = (index) => {
        setEditMeetingIndex(index);
        const meetingToEdit = meetings[index];
        setDate(meetingToEdit.date);
        setLocation(meetingToEdit.location);
    };

    const handleDeleteMeeting = (id) => {
        setDeleteMeetingId(id);
        setShowDelete(true);
    }

    const refreshMeeting = (refresh) => {
        setShowDelete(false);
        setAddMeeting(false);
        if(refresh === true) {
        axios.get(`/api/Events`)
                    .then((response) => {
                        setMeetings(response.data.data);
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
                }
    }
    const handleSaveMeeting = (id) => {
        const formData = {
            date: date,
            location: location,
        }

        setEditMeetingIndex(-1);

        setLoading(true);
        axios
            .put(`api/Events/${id}`, formData)
            .then(() => {
                setLoading(false);
                axios.get(`/api/Events`)
                    .then((response) => {
                        setMeetings(response.data.data);
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                    });
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const formatGermanDate = (date) => {
        return dayjs(date).locale('de').format('DD.MM.YY - HH:mm');
    };

    const sortByDate = (meetings) => {
        const sortedMeetings = meetings.sort((a, b) => {
            const dateA = dayjs(a.date).toDate();
            const dateB = dayjs(b.date).toDate();
            return dateA - dateB;
        });
        return sortedMeetings;
    };

    const sortedMeetings = sortByDate(meetings);

    return (
        <>
            <div className='row mt-5 '>
                <div className='fw-semibold col rakete-font fs-4 ms-3 text-start text-uppercase rakete-rot'>Grillfeier & Mitgliederversammlung</div>
                <div className='text-end pe-5 col'><HiOutlinePlus size={28} className='hover icon-edit' onClick={() => setAddMeeting(true)} /></div>
            </div>
            <Accordion defaultActiveKey="0">
                <div className='container-fluid'>
                    <div className='justify-content-center'>
                        <div className="px-3 shadow-sm rounded my-3">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header className='border border-0'>
                                    <div className='fw-semibold rakete-font fs-5 ms-3 text-start text-uppercase'>Termine</div>
                                </Accordion.Header>
                                <Accordion.Body>

                                    <div className=' fs-7 mx-1 pb-2'>
                                        <div className="text-center">
                                            {sortedMeetings && sortedMeetings.map((meeting, index) => (
                                                <div key={meeting._id} className="rounded-5 row py-2 align-items-center events justify-content-center">
                                                    {editMeetingIndex === index ? (
                                                        <>
                                                            <div className='col mt-2'>
                                                                <div className='row justify-content-center align-items-center my-2'>
                                                                    <Form.Group as={Col}>
                                                                        <Form.Label className="rakete-font rakete-rot text-uppercase fw-semibold fs-8 me-3 mt-4">Datum</Form.Label>
                                                                        <DateTimePicker
                                                                            selected={date}
                                                                            onChange={(newDate) => setDate(newDate)}
                                                                        />
                                                                    </Form.Group>
                                                                    <div className='col-1 text-uppercase rakete-font fs-8 rakete-rot text-end fw-semibold align-items-center'>Ort</div>
                                                                    <Form.Group as={Col}>
                                                                        <Form.Control
                                                                            type="text"
                                                                            className='form-control-lg text-cursor'
                                                                            value={location}
                                                                            onChange={(e) => setLocation(e.target.value)}
                                                                        />
                                                                    </Form.Group >
                                                                </div>
                                                            </div>
                                                            <div className="col-3 text-end me-3">
                                                                <FaCheck size={19} className='hover icon-edit mx-1 my-1 check' onClick={() => handleSaveMeeting(meeting._id)} />
                                                                <IoClose size={22} className='hover icons mx-1 my-1 decline ' onClick={() => setEditMeetingIndex(-1)} />
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div className='row d-flex justify-content-center align-items-center my-3'>
                                                            <div className='col-3 fw-semibold end rakete-font'>
                                                                {formatGermanDate(meeting.date)} Uhr
                                                            </div>
                                                            <div className='col text-start'>
                                                                {meeting.location}
                                                            </div>
                                                            <div className="col-4 text-end align-items-center">
                                                                <TbEditCircle size={21} className='hover icon-edit mx-1 my-1' onClick={() => handleEditMeeting(index)} />
                                                                <RiDeleteBinLine size={22} className='hover icons mx-1 my-1' onClick={() => handleDeleteMeeting(meeting._id)} />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </div>
                    </div>
                    <MeetingAdd show={addMeeting} onHide={refreshMeeting} />
                    <MeetingDelete show={showDelete} id={deleteMeetingId} onHide={refreshMeeting} />
                </div>
            </Accordion >
        </>
    )
}

export default Meetings;
