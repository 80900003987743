import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { RiLockPasswordLine } from "react-icons/ri";
import { TbEditCircle } from "react-icons/tb";
import { RiDeleteBinLine } from "react-icons/ri";
import MemberDelete from './memberDelete';
import { getRoleLabel } from '../../helpers/role';
import MemberEdit from './memberEdit';
import formattedDate from '../../helpers/formattedDate';
import { useAuth } from '../../provider/auth';
import MemberPassword from './memberPassword';

function MemberDetail({ show, onHide, member }) {
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { user, rights } = useAuth();
  useEffect(() => {
    if (showDelete) {
      setShowEdit(false);
      setShowPassword(false);
    }
    if (showEdit) {
      setShowDelete(false);
      setShowPassword(false);
    }
    if (showPassword) {
      setShowDelete(false);
      setShowEdit(false);
    }
  }, [showEdit, showDelete, showPassword]);

  if (!member) {
    return null
  }

  if (member._id === user) {
    return (
      <Modal show={show} onHide={() => { onHide(); setShowEdit(false); setShowDelete(false); setShowPassword(false)}} centered size="sm">
        <Modal.Header className='bg-light justify-content-center border border-0 rakete-rot' closeButton>
          <Modal.Title className='rakete-font fw-semibold text-uppercase fs-6'>Dein Profil</Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-light shadow'>
          {(!showEdit)&&(<img src={`https://server.raketefreiburg.de/uploads/${member.picture}`} className="profile-image" alt={`News: ${member.forename}`} />)}
          {!(showEdit || showDelete || showPassword) && (
            <div>
              <div className="fs-8 text-uppercase rakete-rot rakete-font mt-3">Name</div>
              <p className="fs-7">{member.forename} {member.lastname}</p>
              <div className="fs-8 text-uppercase rakete-rot rakete-font mt-3">Email</div>
              <p className="fs-7">{member.email}</p>
              <div className="fs-8 text-uppercase rakete-rot rakete-font mt-3">Rolle</div>
              <p className="fs-7 text-capitalize">{getRoleLabel(member.role)}</p>
              <div className="fs-8 text-uppercase rakete-rot rakete-font mt-3">registriert seit</div>
              <p className="fs-7">{formattedDate(member.createdAt)}</p>
            </div>
          )}
          <MemberEdit showEdit={showEdit} onHide={() => setShowEdit(false)} member={member} />
          <MemberDelete show={showDelete} onHide={() => setShowDelete(false)} id={member._id} />
          <MemberPassword show={showPassword} onHide={() => setShowPassword(false)} id={member._id} />
        </Modal.Body>
        <Modal.Footer className='border border-0 fs-8'>
          <RiLockPasswordLine size={26} className={` icons mx-3 my-1 ${showPassword ? 'icon-active' : 'hover'}`} title="Passwort zurücksetzen" onClick={() => { setShowPassword(!showPassword); setShowEdit(false); setShowDelete(false)}} />
          <TbEditCircle size={25} className={` icon-edit mx-3 my-1 ${showEdit ? 'icon-edit-active' : 'hover'}`} onClick={() => { setShowEdit(!showEdit); setShowDelete(false); setShowPassword(false) }} />
          {(rights === 2) && (<RiDeleteBinLine size={24} className={` icons mx-3 my-1 ${showDelete ? 'icon-active' : 'hover'}`} onClick={() => { setShowDelete(!showDelete); setShowEdit(false); setShowPassword(false); }} />)}
        </Modal.Footer>
      </Modal>
    )
  };

  return (
    <Modal show={show} onHide={() => { onHide(); setShowEdit(false); setShowDelete(false); }} centered size="sm">
      <Modal.Header className='bg-light justify-content-center border border-0 rakete-rot' closeButton>
        <Modal.Title className='rakete-font fw-semibold text-uppercase fs-6'>{member.forename} {member.lastname}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='bg-light shadow'>
        <img src={`https://server.raketefreiburg.de/uploads/${member.picture}`} className="profile-image" alt={`News: ${member.forename}`} />
        {!(showEdit || showDelete) && (
          <div>
            <div className="fs-8 text-uppercase rakete-rot rakete-font mt-3">Email</div>
            <p className="fs-7">{member.email}</p>
            <div className="fs-8 text-uppercase rakete-rot rakete-font mt-3">Rolle</div>
            <p className="fs-7 text-capitalize">{getRoleLabel(member.role)}</p>
            <div className="fs-8 text-uppercase rakete-rot rakete-font mt-3">registriert seit</div>
            <p className="fs-7">{formattedDate(member.createdAt)}</p>
          </div>
        )}
        {(user === member._id || rights === 2) && (
          <>
            <MemberEdit showEdit={showEdit} onHide={() => setShowEdit(false)} member={member} />
            <MemberDelete show={showDelete} onHide={() => setShowDelete(false)} id={member._id} />
            <MemberPassword show={showPassword} onHide={() => setShowPassword(false)} id={member._id} />
          </>
        )}
      </Modal.Body>
      <Modal.Footer className='border border-0 fs-8'>
        {(user === member._id || rights === 2) && (<>
          {(user === member.id) && (<RiLockPasswordLine size={26} className='hover icons mx-3 my-1' title="Passwort zurücksetzen" onClick={() => { setShowPassword(!showPassword); setShowDelete(false); setShowEdit(false);}} />)}
          <TbEditCircle size={25} className={` icon-edit mx-3 my-1 ${showEdit ? 'icon-edit-active' : 'hover'}`} onClick={() => { setShowEdit(!showEdit); setShowDelete(false); setShowPassword(false); }} />
          {(rights === 2) && (<RiDeleteBinLine size={24} className={` icons mx-3 my-1 ${showDelete ? 'icon-active' : 'hover'}`} onClick={() => { setShowDelete(!showDelete); setShowEdit(false); setShowPassword(false); }} />)}
        </>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default MemberDetail;