import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Row, Col } from 'react-bootstrap';

function HistoryAdd({ show, onHide }) {
    const [year, setYear] = useState(0);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState('');
    const [loading, setLoading] = useState(false);

    const closeHistoryAdd = () => {
        onHide();
    }

    const handleAddHistory = () => {
        const historyData = {
            year: year,
            title: title,
            content: content,
        };

        setLoading(true);

        axios
            .post("/api/History", historyData)
            .then(() => {
                setLoading(false);
                onHide();
                window.location.reload();
            })
            .catch((error) => {
                setLoading(false);
                alert("An error occurred. Please check Console");
                console.log(error);
            });
    };

    return (
        <>
            <Modal show={show} onHide={closeHistoryAdd} size="md">
                <Modal.Header className='rakete-font rakete-rot text-uppercase fw-semibold fs-5' closeButton onClick={closeHistoryAdd}>Neues Ereignis</Modal.Header>
                <Modal.Body className='fs-7'>
                    <Row className="my-2">
                        <Form.Group as={Col}>
                            <Form.Label value="Vorname">Jahr</Form.Label>
                            <Form.Control
                                id="year"
                                type="text"
                                className='form-control-sm'
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                            />
                        </Form.Group >
                        <Form.Group as={Col}>
                            <Form.Label value="Nachname" >Titel</Form.Label>
                            <Form.Control
                                id="title"
                                type="text"
                                className='form-control-sm'
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Form.Group>
                    </Row>
                    <Form.Group className="my-2">
                        <Form.Label value="Content" >Inhalt</Form.Label>
                        <Form.Control
                            id="email"
                            type="email"
                            className='form-control-sm'
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body >
                <Modal.Footer>
                    <button className="text-center login-button text-uppercase rounded fw-semibold border border-0 fs-7" onClick={handleAddHistory} type="submit">Speichern</button>
                    <button className="text-center decline-button text-uppercase rounded fw-semibold fs-7" onClick={closeHistoryAdd}>
                        Verwerfen
                    </button>
                </Modal.Footer>
            </Modal >
        </>
    );
};

export default HistoryAdd;
